// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-404-page-tsx": () => import("./../src/templates/404/404.page.tsx" /* webpackChunkName: "component---src-templates-404-404-page-tsx" */),
  "component---src-templates-briquette-briquette-page-tsx": () => import("./../src/templates/briquette/briquette.page.tsx" /* webpackChunkName: "component---src-templates-briquette-briquette-page-tsx" */),
  "component---src-templates-case-study-case-study-page-tsx": () => import("./../src/templates/caseStudy/caseStudy.page.tsx" /* webpackChunkName: "component---src-templates-case-study-case-study-page-tsx" */),
  "component---src-templates-case-study-list-case-study-list-page-tsx": () => import("./../src/templates/caseStudyList/caseStudyList.page.tsx" /* webpackChunkName: "component---src-templates-case-study-list-case-study-list-page-tsx" */),
  "component---src-templates-event-promo-event-promo-page-tsx": () => import("./../src/templates/eventPromo/eventPromo.page.tsx" /* webpackChunkName: "component---src-templates-event-promo-event-promo-page-tsx" */),
  "component---src-templates-homepage-homepage-page-tsx": () => import("./../src/templates/homepage/homepage.page.tsx" /* webpackChunkName: "component---src-templates-homepage-homepage-page-tsx" */),
  "component---src-templates-machine-comparison-machine-comparison-page-tsx": () => import("./../src/templates/machineComparison/machineComparison.page.tsx" /* webpackChunkName: "component---src-templates-machine-comparison-machine-comparison-page-tsx" */),
  "component---src-templates-products-list-products-list-page-tsx": () => import("./../src/templates/productsList/productsList.page.tsx" /* webpackChunkName: "component---src-templates-products-list-products-list-page-tsx" */),
  "component---src-templates-rent-rent-page-tsx": () => import("./../src/templates/rent/rent.page.tsx" /* webpackChunkName: "component---src-templates-rent-rent-page-tsx" */),
  "component---src-templates-search-search-page-tsx": () => import("./../src/templates/search/search.page.tsx" /* webpackChunkName: "component---src-templates-search-search-page-tsx" */),
  "component---src-templates-single-product-single-product-page-tsx": () => import("./../src/templates/singleProduct/singleProduct.page.tsx" /* webpackChunkName: "component---src-templates-single-product-single-product-page-tsx" */),
  "component---src-templates-text-page-text-page-page-tsx": () => import("./../src/templates/textPage/textPage.page.tsx" /* webpackChunkName: "component---src-templates-text-page-text-page-page-tsx" */)
}

